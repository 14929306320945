import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "item-reviews"
    }}>{`Item Reviews`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`While in the Reviews section of the Reading Tracker, patrons can leave reviews on items. To toggle to the reviews section, select 'Reviews' at the top of the screen. `}</li>
      <li parentName="ol">{`Select 'Write A Review'. They can pick from viewing their current list of loans or by entering in a ISBN.`}</li>
    </ol>
    <p><img alt="Reading Tracker Reviews Screen" src={require("./images/reading_tracker_reviews.png")} />{` `}<img alt="Reading Tracker Select Item Screen" src={require("./images/reading_tracker_select_item.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`The Write A Review page will display. Here the patron can provide their rating by selecting from the animated faces, a scale from 1 - 5, and write down their review in the Your Review text box. `}</li>
      <li parentName="ol">{`Clicking 'Save' will submit the review. `}</li>
      <li parentName="ol">{`A list of created reviews will display for reference. `}</li>
    </ol>
    <p><img alt="Reading Tracker Write Review Screen" src={require("./images/reading_tracker_write_review.png")} />{` `}<img alt="Reading Tracker Submit Review Screen" src={require("./images/reading_tracker_submit_review.png")} />{` `}<img alt="Reading Tracker Review List Screen" src={require("./images/reading_tracker_review_list.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      